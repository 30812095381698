import React from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import ReactMarkdown from 'react-markdown';
import CodeSandboxEmbed from '../../components/CodeSandbox/CodeSandboxEmbed';
import EmbedStackBlitz from '../../components/StackBlitz/StackBlitzEmbed';
import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/github.css';
import { MarkDownView } from '../../components/MarkDownView/MarkDownView';
import { ColumnParameterTable } from './tables/ColumnParameterTable';
import { FreslerTableParameterTable } from './tables/FreslerTableParameterTable';

export const Documentation = () => {

    const sandboxUrl = 'https://codesandbox.io/p/devbox/table-1-fcvqpg?embed=2&file=%2Fsrc%2FApp.tsx';
    const s2 = "https://stackblitz.com/edit/fresler-table-1?embed=1&file=src%2FApp.tsx";

    const header = `
# Developer Documentation
Use the sandbox below to demo the FreslerTable features.
`

    const md0 = `
## Creating A Table
To create a table, simply import the FreslerTable component and provide the data and columns as props. The table will be rendered with the data and columns provided.

**Example:**
\`\`\`jsx
import React, { useState } from 'react';
import './App.css';
import { FreslerTable } from '@fresler/fresler-table';
import '@fresler/fresler-table/css';

// You must remove <React.StrictMode> to allow drag and drop to work
function App() {
  const data = [
    { id: 1, name: 'John Doe', age: 25, email: 'johndoe@jgmail.com' },
    { id: 2, name: 'Fred Baldwin', age: 22, email: 'janedoe@jgmail.com' },
  ];

  const cols = [
    { diplayName: 'Name', field: 'name' },
    { diplayName: 'Age', field: 'age' },
    { diplayName: 'Email', field: 'email' },
  ];

  return (
    <div style={{ width: '90vw', height: '90vh' }}>
      <FreslerTable 
       initData={data} 
       initCols={cols} />
    </div>
  );
}

export default App;
\`\`\`

### FreslerTable Properties
To instantiate the FreslerTable component, the following properties can be provided. All parameters are optional unless otherwise specified.

`

    const md1 = `
# Demo
This is a simple of example of how FreslerTable can be used in a React application. Simply provide json data and a list of columns to display and the table will be rendered with the data and columns provided.
`;

    const md2 = `
## Data
Json data can be provided to the table where each object in the array represents a row in the table. The keys in the object represent the columns in the table and the values represent the data in the cells.

**Example:**
\`\`\`json
[
    {
        "full_name": "John Doe",
        "job_title": "Software Engineer",
        "target": 0.8,
        "interim_title": "Software Engineer",
        "assignee": "dssd"
    }
]
\`\`\`

### Data Types

Currently the table supports the following data types:
- string
- percentage
- select
- createselect
- date
- number
- int
- float


## Columns

Columns can be provided to the table where each object in the array represents a column in the table. The keys in the object represent the properties of the column and the values represent the data in the cells.


**Example:**
\`\`\`js
[
    {
      "displayName": "Name",
      "field": "full_name",
      "type": "string",
      "editable": true,
    },
    {
      "displayName": "Job Title",
      "field": "job_title",
      "type": "string",
      "editable": true,
    }
]
\`\`\`

### Column Properties
`;
    return (
        <PageWrapper title='Documentation'>
            <Page container="fluid">

                <MarkDownView content={header} />
                <CodeSandboxEmbed
                    src={s2}
                />
                <MarkDownView className={"mt-3"} content={md0} />
                <FreslerTableParameterTable />
                <MarkDownView className={"mt-5"} content={md2} />
                <ColumnParameterTable />
            </Page>
        </PageWrapper>
    )
}
