import React from 'react'
import { MarkDownView } from '../../../components/MarkDownView/MarkDownView'

const codeSnippet = `
\`\`\`js
[
    {
      displayName: 'Name',
      field: 'full_name',
      type: 'string',
      editable: true,
    },
    {
      displayName: 'Job Title',
      field: 'job_title',
      type: 'string',
      editable: true,
    }
]
\`\`\`
`

    const columnTypeList = `
- string
- percentage
- select
- createselect
- date
- number
- int
- float
`

    const optionSelectExample = `
\`\`\`jsx
[
    { value: 'Potential', label: <Badge color="light">Potential</Badge> },
    { value: 'Active', label: <Badge color="info">Active</Badge> },
    { value: 'Blocked', label: <Badge color="danger">Blocked</Badge> },
    { value: 'Abandoned', label: <Badge color="primary">Abandoned</Badge> },
    { value: 'Complete', label: <Badge color="success">Complete</Badge> },
]
\`\`\`
`

    const broadcastChangeExample = `
\`\`\`js
{
    displayName: "Status",
    field: "status",
    broadcastChange: (item, col, value) => {
        oldVal = item[col.field]
        console.log(\`Old item \${col.field} value was \${oldVal}. The new value is \${value}\`)
    }
}
\`\`\`
`

    const serializeExample = `
\`\`\`js
{
    displayName: "Company",
    field: "company",
    serialize: (dataItem, col) => {
        const fundName = dataItem?.["company"] ?? "<Unknown>"
        console.log(\`Serialized fund \${fundName}\`)
    }
}
\`\`\`
`

    const columnActionsExample = `
\`\`\`js
const col = {
    displayName: "Status",
    field: "status",
    actions: [
        {
            title: "Mark All Complete",
            icon: "Check",
            runAction: (
                data, col, checkedItems,
                updateItem, updateItemSet, updateData, addItem
            ) => {

                // Check if the user has selected any items and use those to filter the data
                const selectedItems = (checkedItems.size > 0
                    ? data?.filter(x => checkedItems.has(x?._id))
                    : data) ?? []

                const numItems = selectedItems.length
                const updatedTasks = selectedItems.map((item) => {
                    return { ...item, status: "Complete" }
                })

                // Update the data with the new tasks
                updateItemSet(updatedTasks)
            }
        }
    ]
}
\`\`\`
`


export const ColumnParameterTable = () => {
    return (
        <div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Property</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>displayName</td>
                        <td>String</td>
                        <td>
                        (<b>Required</b>) The name of the column to be displayed in the header of the table
                        </td>
                    </tr>
                    <tr>
                        <td>field</td>
                        <td>String</td>
                        <td>(<b>Required</b>) The key in the json data object that corresponds to the column</td>
                    </tr>
                    <tr>
                        <td>type</td>
                        <td>String</td>
                        <td>The type of data in the column <MarkDownView className={'mt-2'} content={columnTypeList} />
                        </td>
                    </tr>
                    <tr>
                        <td>width</td>
                        <td>Number</td>
                        <td>The width of the column in pixels</td>
                    </tr>
                    <tr>
                        <td>options</td>
                        <td>List[Object]</td>
                        <td>
                            Provides the list of options when the type is set to <b>select</b> or <b>createselect</b>.
                            <br /><br /><b>Example:</b>
                            <MarkDownView className={'mt-2'} content={optionSelectExample} />
                        </td>
                    </tr>
                    <tr>
                        <td>editable</td>
                        <td>Boolean</td>
                        <td>
                            Boolean (true or false) determining whether the column is editable or not
                        </td>
                    </tr>
                    <tr>
                        <td>broadcastChange</td>
                        <td>Function</td>
                        <td>
                            Callback function that is called when the data in a cell of the is changed. This can be used to call an specific API to update a portion of the data in the backend.
                            <br /><br /><b>Example:</b>
                            <MarkDownView className={'mt-2'} content={broadcastChangeExample} />
                        </td>
                    </tr>
                    <tr>
                        <td>serialize</td>
                        <td>Function</td>
                        <td>
                            Callback function that is used to serialize the content of the cell.
                            <br /><br /><b>Example:</b>
                            <MarkDownView className={'mt-2'} content={serializeExample} />
                        </td>
                    </tr>
                    <tr>
                        <td>viewRender</td>
                        <td>Function</td>
                        <td>
                            Callback function that is used to serialize the content of the cell.
                            <br /><br /><b>Example:</b>
                            <MarkDownView className={'mt-2'} content={serializeExample} />
                        </td>
                    </tr>
                    <tr>
                        <td>actions</td>
                        <td>List[Function]</td>
                        <td>
                            Column level actions that can be used to apply an operation to every cell in the column.
                            <br /><br /><b>Example:</b>
                            <MarkDownView className={'mt-2'} content={columnActionsExample} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
