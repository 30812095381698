export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Home',
		path: '/',
		icon: 'Home',
		subMenu: null,
	},

	demo: {
		id: 'demo',
		text: 'Demos',
		path: '/demo',
		icon: 'Preview',
		subMenu: null,
	},

	start: {
		id: 'start',
		text: 'Get Started',
		path: '/start',
		icon: 'DirectionsRun',
		subMenu: null,
	},

	why: {
		id: 'why',
		text: 'Why',
		path: '/why',
		icon: 'HelpCenter',
		subMenu: null,
	},

	docs: {
		id: 'why',
		text: 'Documentation',
		path: '/docs',
		icon: 'MenuBook',
		subMenu: null,
	},

	contact: {
		id: 'contact',
		text: 'Contact Us',
		path: '/contact',
		icon: 'ConnectWithoutContact',
		subMenu: null,
	},
};

export const demoPagesMenu = {
	auth: {
		id: 'auth',
		text: 'User Features',
		icon: 'List',
	},
	dataentry: {
		id: 'dataentry',
		text: 'Data Entry',
		path: '/dataentry',
		icon: 'EditNote',
	},
	sparsedata: {
		id: 'sparseData',
		text: 'Sparse Data',
		path: '/sparsedata',
		icon: 'PictureInPicture',
	},
	colmap: {
		id: 'colmap',
		text: 'Column Mapping',
		path: '/colmap',
		icon: 'ViewColumn',
	},
	colactions: {
		id: 'colmap',
		text: 'Column Actions',
		path: '/colmap',
		icon: 'AlignVerticalTop',
	},
	grouping: {
		id: 'grouping',
		text: 'Grouping',
		path: '/grouping',
		icon: 'GroupWork',
	},
	filtering: {
		id: 'filtering',
		text: 'Filtering',
		path: '/filtering',
		icon: 'FilterList',
	},
	customrender: {
		id: 'customrender',
		text: 'Custom Rendering',
		path: '/customrender',
		icon: 'TableView',
	},


};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'devFeatures',
		text: 'Support',
	}
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
