import React from 'react'
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/github.css';
import './MarkDownView.css'
import { Link } from 'react-router-dom';
import remarkGfm from 'remark-gfm'

export const MarkDownView = ({ content, className }) => {
    return (
        <ReactMarkdown
            className={className}
            rehypePlugins={[rehypeHighlight, remarkGfm]}
            components={{
                a: ({ href, children }) => {
                    if (href.startsWith('/')) {
                        return <Link to={href}>{children}</Link>;
                    }
                    return <a href={href}>{children}</a>;
                }
            }}
        >{content}
        </ReactMarkdown>
    )
}
