import React from 'react';
import Footer from '../../../layout/Footer/Footer';
import Popovers from '../../../components/bootstrap/Popovers';
import logo from "../../../assets/img/logo.png"
import { useNavigate } from 'react-router-dom';



const DefaultFooter = () => {

	const navigate = useNavigate();
	const curYear = new Date().getFullYear();

	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col cursor-pointer'
						onClick={() => navigate('/contact')}
					>
						<img
							style={{
								width: '90px',
								height: 'auto',
							}}
							src={logo} alt="logo" />
					</div>
					<div className='col-auto'>
						Copyright Fresler LLC © - {curYear}
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
