import React from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import { MarkDownView } from '../../components/MarkDownView/MarkDownView'
import CodeSandboxEmbed from '../../components/CodeSandbox/CodeSandboxEmbed';

const sparseDataSB = "https://stackblitz.com/edit/vitejs-vite-6gmolx?embed=1&file=src%2FApp.jsx";

const header = `
# Yet Another Table Library?
FreslerTable was created to provide a simple, yet powerful table library for React applications. Today's table libraries
primarily cater to simple scenarios and are not extensible enough to handle complex data structures. FreslerTable was created
to handle complex data structures with ease and provide powerful extensibility.

## Edit-First Design
FreslerTable was designed with the power-users in mind. Rather than create a barrier between viewing and editing data, FreslerTable
allows the user to edit data in-place. This allows the user to quickly make changes to the data without having to navigate to a separate.
FreslerTable also provides various way to group edit data, allowing the user to edit multiple rows at once, change the group of a subset of data
items, perform edits across multiple select rows, and more.

Users can add custom columns to the table to display data in a meaningful way without ever having to write code. They can remap columns to new data 
fields and add new rows to the table easily. This is great for importing customer data into an app and making quick edits to the data without having
to use excel as an intermediary.

This streamlines customer data manipulation and gets them into your app much faster.

![group-edit](/group-edit.png)

## Sparse Data Friendly
Sometimes data is not best kept in a tabular format. FreslerTable allows you to keep your data in a sparse format such as a hashmap.
Combined with the edit-first design, you can create a table that allows the user to edit
sparse data in a meaningful way. This is great for representing and maintaining datasets where the majority of the values are null.

Open the sandbox below to see this concept in action. The example below represents company sentiments across all 50 states. Most of the data is null. We can update the data by 
clicking a cell to change the sentiment value.
`

const otherFeatures = `
## Other Features

- **Column Resizing**: Resize columns by dragging the column header.
- **Column Reordering**: Reorder columns by dragging and dropping the column header.
- **Column Visibility**: Show/hide columns by right-clicking the column header.
- **Column Sorting**: Sort columns by clicking the column header.
- **Column Filtering**: Filter columns by clicking the column header.
- **Column Grouping**: Group columns by clicking the Group By button in the column header dropdown.
- **Column Mapping**: Aggregate columns by clicking the Aggregate button in the column header dropdown.
- **Column Customization**: Customize columns by clicking the Customize button in the column header dropdown.
- **Custom Cell Rendering**: Render cells with custom components.
- **Custom Data Group Actions**: Perform actions on data items grouped by a column, manual selection, or a combination of both.
- **Top search bar**: Search for data items by typing in the search bar.

FreslerTable is designed to be extensible and customizable to fit your needs. If you have a feature request, please open an issue on the 
GitHub repository or [contact us](/contact).
`

export const Why = () => {
    return (
        <PageWrapper title='Why'>
            <Page container="xxl">
                <MarkDownView content={header} />
                <CodeSandboxEmbed src={sparseDataSB} />
                <MarkDownView className="mt-3" content={otherFeatures} />
            </Page>
        </PageWrapper>
    )
}
