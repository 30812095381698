import React from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import ReactMarkdown from 'react-markdown';
import CodeSandboxEmbed from '../../components/CodeSandbox/CodeSandboxEmbed';
import EmbedStackBlitz from '../../components/StackBlitz/StackBlitzEmbed';
import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/github.css';
import { MarkDownView } from '../../components/MarkDownView/MarkDownView';
import { ColumnParameterTable } from './tables/ColumnParameterTable';
import { FreslerTableParameterTable } from './tables/FreslerTableParameterTable';

export const Demo = () => {

    const sandboxUrl = 'https://codesandbox.io/p/devbox/table-1-fcvqpg?embed=2&file=%2Fsrc%2FApp.tsx';
    const s2 = "https://stackblitz.com/edit/fresler-table-1?embed=1&file=src%2FApp.tsx";
    const groupEditSB = "https://stackblitz.com/edit/fresler-table-2?embed=1&file=src%2FApp.tsx";
    const sparseDataSB = "https://stackblitz.com/edit/vitejs-vite-6gmolx?embed=1&file=src%2FApp.jsx";

    const headerAndSanbdox1 = `
# Demos

## Simple Table
This is a simple of example of how FreslerTable can be used in a React application. 
Simply provide json data and a list of columns to display and the table will be rendered 
with the data and columns provided.
`;

    const sandbox2 = `
## Data Input Table With Grouping
This table is customized to display column-field mappings for the customer to perform the mapping themselves. This is especially useful
when the data is not structured and the user needs to map the data to the correct fields. Group editing is also enabled to allow the user to edit multiple rows at once.
`;

    const sandbox3 = `
## Sparse Data And Custom Cell Rendering
Sometimes a list of json objects isn't quite the best way to represent data because the data may be sparse. 
FreslerTable's extensibility allows you to custom render cells to display the data in a meaningful way while 
looking up cell values from a map. This is great for representing datasets where the majority of the values are null.

This table represents company sentiments across all 50 states. Most of the data is null. We can update the data by 
clicking a cell to change the sentiment value.
`

    return (
        <PageWrapper title='Demos'>
            <Page container="fluid">
                <MarkDownView content={headerAndSanbdox1} />
                <CodeSandboxEmbed
                    src={s2}
                />
                <MarkDownView className={'mt-3'} content={sandbox2} />
                <CodeSandboxEmbed
                    src={groupEditSB}
                />
                <MarkDownView className={'mt-3'} content={sandbox3} />
                <CodeSandboxEmbed
                    src={sparseDataSB}
                />
            </Page>
        </PageWrapper>
    )

}
