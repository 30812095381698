import React, { lazy } from 'react';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import { Demo } from '../pages/Demo/Demo';
import { Documentation } from '../pages/Demo/Documentation';
import { GetStarted } from '../pages/Demo/GetStarted';
import { Why } from '../pages/Demo/Why';
import { ContactUs } from '../pages/Demo/ContactUs';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/SummaryPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: "auth-pages/404",
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.sparsedata.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.sparsedata.path,
		element: <Login isSignUp />,
	},
	{
		path: dashboardPagesMenu.demo.path,
		element: <Demo />,
	},
	{
		path: dashboardPagesMenu.docs.path,
		element: <Documentation />,
	},
	{
		path: dashboardPagesMenu.start.path,
		element: <GetStarted />,
	},
	{
		path: dashboardPagesMenu.why.path,
		element: <Why />,
	},
	{
		path: dashboardPagesMenu.contact.path,
		element: <ContactUs />,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	// {
	// 	path: pageLayoutTypesPagesMenu.blank.path,
	// 	element: <PAGE_LAYOUTS.BLANK />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
	// 	element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
	// 	element: <PAGE_LAYOUTS.HEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
	// 	element: <PAGE_LAYOUTS.SUBHEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
	// 	element: <PAGE_LAYOUTS.CONTENT />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
	// 	element: <PAGE_LAYOUTS.ASIDE />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
	// 	element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	// },
];
const contents = [...presentation];

export default contents;
