import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../bootstrap/Button';

const ResponsiveIframe = styled.iframe`
  width: 100%;
  height: 80vh;
  border: 0;
  border-radius: 4px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 300px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }
`;



const CodeSandboxEmbed = ({ src }) => {

    const [enableView, setEnableView] = useState(false)

    return (
        <>
            {
                (!enableView) && <div>
                    <Button
                        color='info'
                        size='lg'
                        onClick={() => setEnableView(!enableView)}
                    >
                        {enableView ? 'Hide' : 'Show'} CodeSandbox
                    </Button>
                </div>
            }
            {
                enableView &&
                <>
                    <div className='text-danger'>Sandbox loading/rendering may take over 30 seconds...</div>

                    <ResponsiveIframe
                        src={src}
                        style={{
                            width: '100%',
                            height: '80vh',
                            border: '0',
                            borderRadius: '4px',
                            overflow: 'hidden',
                        }}
                        title="CodeSandbox Embed"
                        allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
                        sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
                    ></ResponsiveIframe>
                </>
            }
        </>
    );
};

export default CodeSandboxEmbed;
