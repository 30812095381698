import React from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import { MarkDownView } from '../../components/MarkDownView/MarkDownView'
import CodeSandboxEmbed from '../../components/CodeSandbox/CodeSandboxEmbed';
import logo from '../../assets/img/logo.png'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import { useNavigate } from 'react-router-dom';



export const ContactUs = () => {

    const navigate = useNavigate();

    return (
        <PageWrapper title='Contact Us'>
            <Page container="xxl">
                <div className='d-flex justify-content-center'>
                    <a
                        href='https://fresler.com'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img
                            style={{ width: '80vw', height: 'auto' }}
                            src={logo} alt="Fresler Logo" />
                    </a>
                </div>
                <h3 >
                    We are a small group of developers who are passionate about creating software
                    that is easy to use and understand. We are always looking for ways to improve
                    our products and services. If you have any questions or suggestions,
                    please feel free to contact us via any of the channels below.
                </h3>
                <h3 className='mt-3'>

                    We are actively looking for feedback. Please use the Github link create a new issue.
                </h3>
                < row className='row mt-5'>
                    <div className='col-12 col-md-6'>
                        <a href="https://github.com/gas6262/fresler-table-examples/issues" target="_blank" rel="noreferrer">
                            <Card>
                                <CardBody>
                                    <CardLabel icon='Code' iconColor='primary'>
                                        <CardTitle>Github</CardTitle>
                                    </CardLabel>
                                </CardBody>
                            </Card>
                        </a>
                    </div>
                    <div className='col-12 col-md-6'>
                        <a href="mailto:contact@fresler.com" target="_blank" rel="noreferrer">
                            <Card>
                                <CardBody>
                                    <CardLabel icon='Email' iconColor='warning'>
                                        <CardTitle>Email</CardTitle>
                                    </CardLabel>
                                </CardBody>
                            </Card>
                        </a>
                    </div>
                    <div className='col-12 col-md-6'>
                        <a href="https://fresler.com" target="_blank" rel="noreferrer">
                            <Card>
                                <CardBody>
                                    <CardLabel icon='Public' iconColor='info'>
                                        <CardTitle>Fresler.com</CardTitle>
                                    </CardLabel>
                                </CardBody>
                            </Card>
                        </a>
                    </div>
                    <div className='col-12 col-md-6'>
                        <a href="https://dgaspard.com" target="_blank" rel="noreferrer">
                            <Card>
                                <CardBody>
                                    <CardLabel icon='Person' iconColor='info'>
                                        <CardTitle>dgaspard.com</CardTitle>
                                    </CardLabel>
                                </CardBody>
                            </Card>
                        </a>
                    </div>
                </row>
            </Page>
        </PageWrapper>
    )
}
