import React from 'react'
import { MarkDownView } from '../../../components/MarkDownView/MarkDownView'

const updateDataExample = `
\`\`\`js
function App() {

    const [tableData, setTableData] = useState(second)
  
    ...
  
    return (
      <div style={{ width: '90vw', height: '90vh' }}>
        <FreslerTable 
         initData={data} 
         initCols={col}
         updateData={(newData) => setTableData(data)}
         />
      </div>
    );
}
\`\`\`
`

const optionalColsExample = `
\`\`\`js
const optionalCols = [
    { diplayName: 'ID', field: 'id' },
];
\`\`\`
`


const actionsExample = `
\`\`\`js
export const deleteAction = {
    displayName: 'Delete',
    icon: 'Delete',
    color: 'danger',
    apply: (data, selected, updateCheckedItems,  updateData, updateDataItem) => {
        const newData = data.filter((item) => !selected.has(item.id ?? item._id));
        updateData(newData);
        updateCheckedItems(new Set())
    }
}
\`\`\`
`

const initColExample = `
\`\`\`js
<FreslerTable 
    initData={data} 
    initCols={cols}
    initGroupBy={cols[0]}
/>
\`\`\`
`

export const FreslerTableParameterTable = () => {
    return (
        <div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Property</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>initData</td>
                        <td>List[Object]</td>
                        <td>
                            (<b>Required</b>) The table data to be displayed in json form. See example code above.
                        </td>
                    </tr>
                    <tr>
                        <td>initCols</td>
                        <td>List[Object]</td>
                        <td>
                            (<b>Required</b>) The table columns to be displayed in json form. See example code above.
                        </td>
                    </tr>
                    <tr>
                        <td>updateData</td>
                        <td>Function</td>
                        <td>
                            A function that can be provided to the table library to update all of the data in the table.
                            The function should take the updated data as an argument. This provided function is intended to 
                            update all of the data in the table. If this function is not provided, data state will be maintained 
                            by the FreslerTable component itself.
                            <br /><br /><b>Example:</b>
                            <MarkDownView className={'mt-2'} content={updateDataExample} />
                        </td>
                    </tr>
                    <tr>
                        <td>setOutputCols</td>
                        <td>Function</td>
                        <td>
                            Callback function to update the table columns.
                            <MarkDownView className={'mt-2'} content={`\`\`\`setOutputCols={(newCols) => setCols(newCols)} \`\`\``} />
                        </td>
                    </tr>
                    <tr>
                        <td>optionalCols</td>
                        <td>List[Object]</td>
                        <td>
                            Columns that are not required to be displayed in the table but can be toggled on and off by the 
                            user through the <i>Edit Column</i> panel.
                            <br /><br /><b>Example:</b>
                            <MarkDownView className={'mt-2'} content={optionalColsExample} />
                        </td>
                    </tr>
                    <tr>
                        <td>customActions</td>
                        <td>List[Function]</td>
                        <td>
                            List of functions that can be applied to selected rows in the table. It is intended that these 
                            actions are to be performed on the entire row rather than individual cells/properties.
                            An example would be item deletion, which is included in the library by default.
                            <br /><br /><b>Example:</b>
                            <MarkDownView className={'mt-2'} content={actionsExample} />
                        </td>
                    </tr>
                    <tr>
                        <td>initDisplayTableMapping</td>
                        <td>Boolean</td>
                        <td>
                            If set to true, the column field mapping dropdown will be displayed in the table header. 
                            This allows the user to map data columns to fields through the UI. This is especially useful for 
                            importing datasets where the column names may not match the field names.
                        </td>
                    </tr>
                    <tr>
                        <td>initGroupBy</td>
                        <td>Object</td>
                        <td>
                            The column which should be used to group data initially upon display. You should reference the original column object rather than create a new/duplicate column.
                            <br /><br /><b>Example:</b>
                            <MarkDownView className={'mt-2'} content={initColExample} />
                        </td>
                    </tr>
                    <tr>
                        <td>isTableOptionsAccessible</td>
                        <td>Boolean (default true)</td>
                        <td>
                            Whether the table options are accessible to the user. If set to false, the user will not be able to
                            options such the <i>Edit Column</i> panel or toggle data column mapping.
                        </td>
                    </tr>
                    <tr>
                        <td>enableColumnDrag</td>
                        <td>Boolean (default true)</td>
                        <td>
                            Enable or disable column reordering by dragging and dropping the column headers.
                        </td>
                    </tr>
                    <tr>
                        <td>enableRowSelection</td>
                        <td>Boolean (default true)</td>
                        <td>
                            Enable or disable row selection in the table. If set to true, a checkbox will be displayed in the first column of the table.
                        </td>
                    </tr>
                    <tr>
                        <td>enableNewItemAdd</td>
                        <td>Boolean (default true)</td>
                        <td>
                            Enable or disable the ability to add new items to the table data via the "Add New" field at the bottom.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
